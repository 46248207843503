import { useEffect, useState } from 'react';

const useTabFocus = () => {
    const [isActive, setIsActive] = useState({
        tabActive: document.visibilityState === 'visible',
        windowFocused: document.hasFocus(),
    });

    useEffect(() => {
        const handleVisibilityChange = () => {
            setIsActive((prevState) => ({
                ...prevState,
                tabActive: document.visibilityState === 'visible',
            }));
        };

        const handleFocus = () => {
            setIsActive((prevState) => ({
                ...prevState,
                windowFocused: true,
            }));
        };

        const handleBlur = () => {
            setIsActive((prevState) => ({
                ...prevState,
                windowFocused: false,
            }));
        };

        // Add event listeners
        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);

        // Clean up the event listeners on component unmount
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('blur', handleBlur);
        };
    }, []);

    return isActive;
};

export default useTabFocus;
