import React, { useState } from 'react';
import useMediaPermissionsCheck from '../components/hooks/useMediaPermissionsCheck';

const PermissionDenied = () => {
    const [permissionMessage, setPermissionMessage] = useState('');

    const retryPermissions = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            // Stop the streams as we just need to check the permission
            stream.getTracks().forEach(track => track.stop());
            setPermissionMessage('Permissions granted! Please proceed to previous screen.');
        } catch (error) {
            // Permissions denied or other error
            // navigate(redirectPath);
        }
    };

    const goBack = () => {
        // Logic to go back to the previous screen
        window.history.back();
    };

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        background: 'linear-gradient(120deg, #12c2e9, #c471ed, #f64f59)',
        color: 'white',
        textAlign: 'center',
        padding: '20px'
    };

    const buttonStyle = {
        margin: '10px',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    };

    const retryButtonStyle = {
        ...buttonStyle,
        backgroundColor: '#f64f59',
    };

    const backButtonStyle = {
        ...buttonStyle,
        backgroundColor: '#c471ed',
    };

    return (
        <div style={containerStyle}>
            <h1>Need a Little Help!</h1>
            <p>Hey there! We need your help to enable video and audio. It's just a click away!</p>
            {permissionMessage && <p>{permissionMessage}</p>}
            <button style={retryButtonStyle} onClick={retryPermissions}>Retry Enabling Permissions</button>
            <button style={backButtonStyle} onClick={goBack}>Go Back</button>
        </div>
    );
};

export default PermissionDenied;
