import React, { useEffect, useRef, useState } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-xml';
import 'ace-builds/src-noconflict/mode-ruby';
import 'ace-builds/src-noconflict/mode-sass';
import 'ace-builds/src-noconflict/mode-markdown';
import 'ace-builds/src-noconflict/mode-mysql';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-handlebars';
import 'ace-builds/src-noconflict/mode-golang';
import 'ace-builds/src-noconflict/mode-csharp';
import 'ace-builds/src-noconflict/mode-elixir';
import 'ace-builds/src-noconflict/mode-typescript';
import 'ace-builds/src-noconflict/mode-css';

import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/theme-kuroir';
import 'ace-builds/src-noconflict/theme-twilight';
import 'ace-builds/src-noconflict/theme-xcode';
import 'ace-builds/src-noconflict/theme-textmate';
import 'ace-builds/src-noconflict/theme-solarized_dark';
import 'ace-builds/src-noconflict/theme-solarized_light';
import 'ace-builds/src-noconflict/theme-terminal';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Stack,
    Container,
    Grid,
    Box,
    Zoom,
} from '@mui/material';
import MeetingDialog from '../meeting/MeetingDialog';
import { dispatch, useSelector } from '../../store';
import { resetCurrentProgramDetails, updateCodingPrograms } from '../../store/slices/interviewsSlice';
import LoadingButton from '../LoadingButton';

const languages = [
    'javascript',
    'java',
    'python',
    'xml',
    'ruby',
    'sass',
    'markdown',
    'mysql',
    'json',
    'html',
    'handlebars',
    'golang',
    'csharp',
    'elixir',
    'typescript',
    'css',
];

const themes = [
    'monokai',
    'github',
    'tomorrow',
    'kuroir',
    'twilight',
    'xcode',
    'textmate',
    'solarized_dark',
    'solarized_light',
    'terminal',
];

const getDefaultCodeSnippet = (language) => {
    switch (language) {
        case 'javascript':
            return 'console.log("Hello, World!");';
        case 'java':
            return 'public class HelloWorld {\n  public static void main(String[] args) {\n    System.out.println("Hello, World!");\n  }\n}';
        case 'python':
            return 'print("Hello, World!")';
        case 'xml':
            return '<root>\n    <element attribute="value">Hello, World!</element>\n</root>';
        case 'ruby':
            return 'puts "Hello, World!"';
        case 'sass':
            return '$color: red;\n\nbody {\n  color: $color;\n}';
        case 'markdown':
            return '# Hello, World!\n\nThis is a markdown document.';
        case 'mysql':
            return 'CREATE TABLE users (\n  id INT PRIMARY KEY,\n  username VARCHAR(255),\n  email VARCHAR(255)\n);';
        case 'json':
            return '{\n  "name": "John Doe",\n  "age": 30,\n  "city": "New York"\n}';
        case 'html':
            return '<!DOCTYPE html>\n<html lang="en">\n<head>\n    <meta charset="UTF-8">\n    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n    <title>Hello, World!</title>\n</head>\n<body>\n    <h1>Hello, World!</h1>\n</body>\n</html>';
        case 'handlebars':
            return '<h1>{{title}}</h1>\n<p>{{content}}</p>';
        case 'golang':
            return 'package main\n\nimport "fmt"\n\nfunc main() {\n    fmt.Println("Hello, World!")\n}';
        case 'csharp':
            return 'using System;\n\nclass Program\n{\n    static void Main()\n    {\n        Console.WriteLine("Hello, World!");\n    }\n}';
        case 'elixir':
            return 'IO.puts "Hello, World!"';
        case 'typescript':
            return 'console.log("Hello, World!");';
        case 'css':
            return 'body {\n    font-family: Arial, sans-serif;\n    background-color: #f0f0f0;\n    color: #333;\n}';
        default:
            return '';
    }
};

const AceEditorComponent = ({ handleEditorSubmit, onExited, show, currentQuestion, handleEditorSave }) => {
    const [language, setLanguage] = useState('javascript');
    const [theme, setTheme] = useState('monokai');
    const [editorCode, setEditorCode] = useState(getDefaultCodeSnippet(language));
    const [isMeetingDialogVisible, setMeetingDialogVisible] = useState(false);
    const [inProp, setInProp] = useState(false);

    const { interviewsData, currentProgramId, isLoading } = useSelector((state) => state.interviews);

    useEffect(() => {
        if (show) {
            setInProp(show);
        }
    }, [show]);

    const handleExited = () => {
        setInProp(false);
        if (onExited) onExited(); // Callback to parent component
    };

    const handleChangeLanguage = (e) => {
        const newLanguage = e.target.value;
        setLanguage(newLanguage);
        setEditorCode(getDefaultCodeSnippet(newLanguage));
    };

    const handleChangeTheme = (e) => {
        setTheme(e.target.value);
    };

    const onEditorSubmit = (e) => {
        setMeetingDialogVisible(true);
    };

    const handleChangeEditor = (value) => {
        setEditorCode(value);
    };

    const handleMeetingDialogCancel = () => {
        setMeetingDialogVisible(false);
    }

    const handleMeetingDialogSubmit = () => {
        setEditorCode(getDefaultCodeSnippet(language));
        handleEditorSubmit();
        saveCode(2);
        setMeetingDialogVisible(false);
    }


    const handleSave = async () => {
        await saveCode(1);
        handleEditorSave();
    }

    async function saveCode(statuss) {
        let final_payload = {
            ivid: interviewsData[0]?.ivid,
            question: currentQuestion,
            solution: editorCode,
            status: statuss,
        }
        dispatch(updateCodingPrograms(currentProgramId, final_payload));
        if (statuss == 2) {
            dispatch(resetCurrentProgramDetails());
        }
    }

    const handleEditorLoad = (editor) => {
        // Override the 'copy' command
        dispatch(resetCurrentProgramDetails());
        editor.commands.addCommand({
            name: 'blockCopy',
            bindKey: { win: 'Ctrl-C', mac: 'Command-C' },
            exec: (editor) => {
                console.log('Copy is disabled');
                // Optionally, you can implement your own logic here
            },
            readOnly: true
        });

        // Override the 'paste' command
        editor.commands.addCommand({
            name: 'blockPaste',
            bindKey: { win: 'Ctrl-V', mac: 'Command-V' },
            exec: (editor) => {
                console.log('Paste is disabled');
                // Optionally, you can implement your own logic here
            },
            readOnly: true
        });

        editor.commands.addCommand({
            name: 'blockCut',
            bindKey: { win: 'Ctrl-X', mac: 'Command-X' },
            exec: () => {
                console.log('Cut is disabled');
            },
            readOnly: true
        });
    };

    return (
        <Zoom in={inProp} onExited={handleExited} unmountOnExit>
            <Container maxWidth="xl" >
                <Box sx={{ pt: 2 }} >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                            <Stack direction="row" spacing={2} alignItems="center">
                                <FormControl fullWidth>
                                    <InputLabel id="language-label">Language</InputLabel>
                                    <Select
                                        labelId="language-label"
                                        id="language-select"
                                        value={language}
                                        label="Language"
                                        onChange={handleChangeLanguage}
                                    >
                                        {languages.map((lang) => (
                                            <MenuItem key={lang} value={lang}>
                                                {lang}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth>
                                    <InputLabel id="theme-label">Theme</InputLabel>
                                    <Select
                                        labelId="theme-label"
                                        id="theme-select"
                                        value={theme}
                                        label="Theme"
                                        onChange={handleChangeTheme}
                                    >
                                        {themes.map((t) => (
                                            <MenuItem key={t} value={t}>
                                                {t}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <LoadingButton variant="contained" onClick={handleSave} isLoading={isLoading}>
                                    Save
                                </LoadingButton>

                                <LoadingButton variant="contained" color="success" onClick={onEditorSubmit} isLoading={isLoading}>
                                    Submit
                                </LoadingButton>
                            </Stack>
                        </Grid>

                        <Grid item xs={12}>
                            <AceEditor
                                onLoad={handleEditorLoad}
                                mode={language}
                                theme={theme}
                                onChange={handleChangeEditor}
                                name="codeEditorDev"
                                editorProps={{ $blockScrolling: true }}
                                width="100%"
                                height="600px"
                                fontSize={18}
                                placeholder='Write your code here...'
                                showPrintMargin={false}
                                showGutter={true}
                                highlightActiveLine={true}
                                value={editorCode}
                                setOptions={{ useWorker: false }}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <MeetingDialog dialogTitle="Are you done?" handleCancel={handleMeetingDialogCancel} handleSubmit={handleMeetingDialogSubmit} isMeetingModalOpen={isMeetingDialogVisible} primaryMessage="Please confirm code submission, once submitted i will proceed to next question!" submitButtonText="Confirm!" key="1" showCancelButton="true" showConfirmationQuestion="false" showPrimaryMessage="true" showSubmitButton="true" />
            </Container>
        </Zoom>
    );
};

export default AceEditorComponent;