import { dispatch } from "../store";
import { uploadFileToServer } from "../store/slices/interviewsSlice";
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { toBlobURL, fetchFile } from "@ffmpeg/util";

const AUDIO_DBNAME = "AudioDatabase";
const VIDEO_DBNAME = "VideoDatabase";
const AUDIO_DBVERSION = 3;
const SS_DBNAME = "ScreenCapDatabase";
const ss_storeName = "captures";
const vid_storeName = "videos";
const aud_storeName = "audioChunks";

const ffmpeg = new FFmpeg({ log: true });

function openVideoDB() {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(VIDEO_DBNAME, 1);

        request.onerror = (event) => {
            console.error("Database error: " + event.target.errorCode);
            reject(event.target.errorCode);
        };

        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            db.createObjectStore(vid_storeName, { autoIncrement: true });
        };

        request.onsuccess = (event) => {
            resolve(event.target.result);
        };
    });
}

function openAudioDB() {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(AUDIO_DBNAME, AUDIO_DBVERSION);

        request.onerror = (event) => {
            console.error("Database error: " + event.target.errorCode);
            reject(event.target.errorCode);
        };

        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            db.createObjectStore(aud_storeName, { autoIncrement: true });
        };

        request.onsuccess = (event) => {
            resolve(event.target.result);
        };
    });
}

function openSSDB() {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(SS_DBNAME, 1);

        request.onerror = (event) => {
            console.error("Database error: " + event.target.errorCode);
            reject(event.target.errorCode);
        };

        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            db.createObjectStore(ss_storeName, { autoIncrement: true });
        };

        request.onsuccess = (event) => {
            resolve(event.target.result);
        };
    });
}

// const compressAndSaveVideo = async (videoBlob, uniq_id) => {
//     console.log("compressAndSaveVideo", videoBlob, uniq_id);
//     if (!ffmpeg.loaded) {
//         await ffmpeg.load();
//     }
//     try {
//         // Write the video file to the memory filesystem
//         var fetchedfile = await fetchFile(videoBlob);
//         console.log("fetchedfile", fetchedfile)
//         await ffmpeg.writeFile('input.webm', fetchedfile);

//         // Run ffmpeg command to compress the video
//         let result = await ffmpeg.exec(['-i', 'input.webm', '-vcodec', 'libx264', '-crf', '28', 'output.mp4']);
//         // Read the compressed file from memory filesystem
//         const compressedData = ffmpeg.readFile('output.mp4')
//         const compressedBlob = new Blob([compressedData.buffer], { type: 'video/mp4' });

//         // Save the compressed video locally
//         const url = URL.createObjectURL(compressedBlob);
//         console.log('Video saved at: ', url, result);
//         saveVideoLocally(compressedBlob, uniq_id);
//     } catch (error) {
//         console.error("Compression or file handling failed:", error);
//         throw error;
//     }
// };

async function saveVideoLocally(newBlob, uniq_id) {
    try {
        const db = await openVideoDB(); // Open the database
        const transaction = db.transaction([vid_storeName], "readwrite");
        const store = transaction.objectStore(vid_storeName);
        const request = store.get(uniq_id); // Attempt to fetch the existing video blob using the unique ID

        // Wait for the request to complete
        const existingBlob = await new Promise((resolve, reject) => {
            request.onsuccess = () => resolve(request.result);
            request.onerror = () => reject(request.error);
        });

        let combinedBlob;
        if (existingBlob) {
            // If there's an existing blob, combine it with the new one
            combinedBlob = new Blob([existingBlob, newBlob], { type: 'video/webm' });
        } else {
            // Otherwise, use the new blob as is
            combinedBlob = newBlob;
        }

        const putRequest = store.put(combinedBlob, uniq_id); // Save the combined blob back into the store

        // Wait for the put request to complete
        await new Promise((resolve, reject) => {
            putRequest.onsuccess = () => resolve();
            putRequest.onerror = () => reject(putRequest.error);
        });

        console.log("Video saved successfully.");
    } catch (error) {
        console.error("Error saving video locally:", error);
    }
}

const saveAudioChunks = (audioChunksRef) => {
    return new Promise((resolve, reject) => {
        openAudioDB().then((db) => {
            const transaction = db.transaction([aud_storeName], "readwrite");
            const store = transaction.objectStore(aud_storeName);

            transaction.onerror = function (event) {
                console.error("Transaction error:", event.target.error);
                reject("Transaction failed");
            };

            if (!audioChunksRef.current || audioChunksRef.current.length === 0) {
                reject("No audio chunks to save");
                return;
            }

            store.add({ id: new Date().toISOString(), chunks: audioChunksRef.current })
                .onsuccess = function () {
                    audioChunksRef.current = [];
                    resolve("Audio chunks saved successfully");
                };
        }).catch(console.error);
    });
};

const addCapture = async (data) => {
    try {
        const db = await openSSDB(); // Ensure this opens/creates an IndexedDB
        const transaction = db.transaction([ss_storeName], 'readwrite');
        const store = transaction.objectStore(ss_storeName);
        const request = store.add(data); // No key specified, IDB will auto-increment

        request.onsuccess = () => {
            // console.log("Video chunk added to the store with generated ID:", request.result);
        };

        request.onerror = (event) => {
            console.error("Failed to add video chunk to the store:", event.target.error);
        };

        transaction.oncomplete = () => {
            // console.log("Transaction completed for storing video chunk.");
            db.close();
        };

        transaction.onerror = (event) => {
            console.error("Transaction error during storage:", event.target.error);
            db.close();
        };
    } catch (error) {
        console.error("Error opening IndexedDB:", error);
    }
};

async function handleSSUpload(ivid, uid) {
    openSSDB().then((db) => {
        const transaction = db.transaction([ss_storeName], "readonly");
        const store = transaction.objectStore(ss_storeName);
        const request = store.getAll(); // Fetches all entries in the store

        request.onsuccess = async (event) => {
            const chunks = event.target.result;
            if (chunks.length > 0) {
                const videoBlob = new Blob(chunks, { type: 'video/webm' });
                await dispatch(uploadFileToServer(videoBlob, uid, 'ss'));
                clearAllCaptures();
                db.close();
            } else {
                console.log('No video chunks to upload.');
            }
        };

        request.onerror = (event) => {
            console.error("Failed to fetch chunks from IndexedDB:", event.target.error);
        };
    });

    // try {
    //     const blobs = await getAllCaptures();
    //     if (blobs.length > 0) {
    //         const combinedBlob = new Blob(blobs, { type: 'video/webm' }); // Combine all blobs into one
    //         await dispatch(uploadFileToServer(combinedBlob, ivid, 'ss'));
    //         console.log('All captures uploaded successfully.');
    //         await clearAllCaptures();
    //     } else {
    //         console.log('No video captures to upload.');
    //     }
    // } catch (error) {
    //     console.error('Failed to upload captures:', error);
    // }
}

function clearAllCaptures() {
    return new Promise((resolve, reject) => {
        openSSDB().then((db) => {
            const transaction = db.transaction([ss_storeName], "readwrite");
            const store = transaction.objectStore(ss_storeName);
            const request = store.clear();

            request.onerror = (event) => {
                console.error("Error clearing the store:", event.target.errorCode);
                reject(event.target.errorCode);
            };

            request.onsuccess = () => {
                console.log("All captures have been cleared from the store.");
                resolve();
                db.close();
            };
        }).catch(console.error);
    });
}

function clearIndexedDB() {
    clearAudioChunks();
    clearAllCaptures();
    deleteVideoFromIndexedDB();
}

function clearAudioChunks() {
    openAudioDB().then((db) => {
        const transaction = db.transaction([aud_storeName], "readwrite");
        const store = transaction.objectStore(aud_storeName);

        // Clear all records in the store
        const clearRequest = store.clear();

        clearRequest.onsuccess = function () {
            console.log("AudioChunks store cleared successfully.");
        };

        clearRequest.onerror = function () {
            console.error("Failed to clear AudioChunks store:", clearRequest.error);
        };
    }).catch(console.error);
}

function deleteVideoFromIndexedDB() {
    openSSDB().then((db) => {
        const deleteTransaction = db.transaction([vid_storeName], "readwrite");
        const store = deleteTransaction.objectStore(vid_storeName);
        const deleteRequest = store.clear();

        deleteRequest.onsuccess = function () {
            console.log("Video deleted from the database.");
        };

        deleteRequest.onerror = function (event) {
            console.error("Error deleting video:", event.target.errorCode);
        };
    }).catch(console.error);
}

export { saveAudioChunks, addCapture, handleSSUpload, clearIndexedDB, clearAudioChunks, deleteVideoFromIndexedDB, openAudioDB, openVideoDB, saveVideoLocally }
