import ZoomUI from "./pages/meetingUI";
import InitiateMeeting from "./pages/initiatemeet";
import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../src/store/index'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ThankYou from "./pages/thankyou";
import CommonErrors from "./pages/commonerrors";
import ErrorBoundary from "./components/ErrorBoundary";
import PreInterviewTests from "./pages/preInterviewTests";
import PermissionDenied from "./pages/permissiondenied";
import Testing from "./pages/testing";
import VideoTest from "./pages/videotest";
import AceEditorComponent from "./components/testing/aceeditorcomponent";


function App() {

  return (
    <Provider store={store}>
      {/* <ErrorBoundary> */}
      <Router>
        <Routes>
          <Route path="/initmeeting" element={<InitiateMeeting />} />
          <Route path="/meeting" element={<ZoomUI />} />
          <Route path="/thankyou" element={<ThankYou />} />
          <Route path="/meetingerror" element={<CommonErrors />} />
          <Route path="/devicetest" element={<PreInterviewTests />} />
          <Route path="/mediapermissiondenied" element={<PermissionDenied />} />
          <Route path="/testing" element={<VideoTest />} />
          <Route path="/monaco" element={<AceEditorComponent />} />
        </Routes>
      </Router>
      {/* </ErrorBoundary> */}
    </Provider>
  );
}
export default App;


