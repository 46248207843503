import { useState, useEffect, useRef } from 'react';

// Custom hook
function useCameraObstructionDetector() {
    const videoRefInt = useRef(null);
    const canvasRefInt = useRef(null);
    const [obstructionDetected, setObstructionDetected] = useState(false);

    useEffect(() => {
        async function getVideo() {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            videoRefInt.current.srcObject = stream;
            videoRefInt.current.onloadedmetadata = () => {
                videoRefInt.current.play();
                canvasRefInt.current.width = videoRefInt.current.videoWidth;
                canvasRefInt.current.height = videoRefInt.current.videoHeight;
                analyzeFrame();
            };
        }
        getVideo();
    }, []);

    const analyzeFrame = () => {
        if (!videoRefInt.current || videoRefInt.current.readyState < 2) {
            console.log('Video not ready');
            return;
        }

        const canvas = canvasRefInt.current;
        const context = canvas.getContext('2d');
        context.drawImage(videoRefInt.current, 0, 0, canvas.width, canvas.height);
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

        let totalBrightness = 0;
        let pixelCount = 0;

        for (let i = 0; i < imageData.data.length; i += 4) {
            let brightness = 0.34 * imageData.data[i] + 0.5 * imageData.data[i + 1] + 0.16 * imageData.data[i + 2];
            totalBrightness += brightness;
            pixelCount++;
        }

        let averageBrightness = totalBrightness / pixelCount;
        // console.log('Average Brightness:', averageBrightness);

        const someThreshold = 89;
        if (averageBrightness < someThreshold) {
            setObstructionDetected(true);
        } else {
            setObstructionDetected(false);
        }
    };

    useEffect(() => {
        const interval = setInterval(analyzeFrame, 3000); // Analyze frame every 3 seconds
        return () => clearInterval(interval);
    }, []);

    // Return everything that might be needed in the component
    return { videoRefInt, canvasRefInt, obstructionDetected };
}

export default useCameraObstructionDetector;
