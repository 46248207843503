import { useEffect, useRef, useState } from 'react';

const useDetectSilence = (onUpdate, isEnabled) => {
  const silenceTimeoutRef = useRef(null);
  const [isSilent, setIsSilent] = useState(false);

  // console.log("useDetectSilence", isEnabled)
  const handleVolumeChange = (volume) => {
    if (!isEnabled) {
      return;
    }
    console.log("useDetectSilence", volume, isEnabled)

    if (volume < -50) {
      if (!silenceTimeoutRef.current) {
        silenceTimeoutRef.current = setTimeout(() => {
          setIsSilent(true);
          onUpdate && onUpdate({ type: 'silence', message: 'Candidate is silent for more than 10 seconds.' });
        }, 10000);
      }
    } else {
      clearTimeout(silenceTimeoutRef.current);
      silenceTimeoutRef.current = null;
      setIsSilent(false);
    }
  };

  const resetSilenceDetection = () => {
    console.log("resetSilenceDetection", isEnabled)
    clearTimeout(silenceTimeoutRef.current);
    silenceTimeoutRef.current = null;
    setIsSilent(false);
  };

  useEffect(() => {
    return () => {
      clearTimeout(silenceTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (!isEnabled) {
      resetSilenceDetection();
    }
  }, [isEnabled]);

  return { isSilent, handleVolumeChange, resetSilenceDetection };
};

export default useDetectSilence;