import React from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import useAudioQualityInterview from '../components/hooks/useDetectSilence';

export default function Testing() {
    const { transcript, listening, browserSupportsSpeechRecognition } = useSpeechRecognition();

    const {
        isRecording,
        showSilentPrompt,
        backgroundNoiseWarning,
        multipleSpeakersWarning,
        lowVoiceQualityWarning,
        stopRecording,
    } = useAudioQualityInterview();

    React.useEffect(() => {
        if (transcript) {
            // handleTranscriptChange(transcript);
        }
    }, [transcript]);

    if (!browserSupportsSpeechRecognition) {
        return <span>Your browser doesn't support speech recognition.</span>;
    }


    return (
        <div>
            <h1>AI Interview App with Audio Quality Detection</h1>
            <p>Recording: {isRecording ? 'Yes' : 'No'}</p>
            {/* <button onClick={() => SpeechRecognition.startListening({ continuous: true })}>Start Interview</button>
      <button onClick={SpeechRecognition.stopListening}>Pause Listening</button> */}
            <button onClick={stopRecording}>Stop Recording</button>
            {showSilentPrompt && <p>It seems you are silent. Are you done answering?</p>}
            {backgroundNoiseWarning && <p>Background noise detected. Please move to a quieter environment.</p>}
            {multipleSpeakersWarning && <p>Multiple voices detected. Please ensure only one person is speaking.</p>}
            {lowVoiceQualityWarning && <p>Low voice quality detected. Please speak louder for better transcription.</p>}
            <h3>Transcript</h3>
            <p>{transcript}</p>
        </div>
    );
}