import React, { useState, useEffect, useRef } from 'react';
import useCameraObstructionDetector from '../components/hooks/useCameraObstructionDetector';

function VideoTest() {

    const { videoRef, canvasRef, obstructionDetected } = useCameraObstructionDetector();

    return (
        <div>
            <video ref={videoRef} style={{ display: 'none' }}></video>
            <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
            {obstructionDetected && <div>Camera obstruction detected.</div>}
        </div>
    );
}

export default VideoTest;