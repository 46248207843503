import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useMediaPermissionsCheck = (redirectPath) => {
    const navigate = useNavigate();
    useEffect(() => {
        const requestPermissions = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                // Stop the streams as we just need to check the permission
                stream.getTracks().forEach(track => track.stop());
            } catch (error) {
                // Permissions denied or other error
                navigate(redirectPath);
            }
        };

        requestPermissions();
    }, [navigate, redirectPath]);
};

export default useMediaPermissionsCheck;
