import { Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import LoadingButton from "../LoadingButton";
import { useState } from "react";


export default function EndMeetingWarningDialog({ handleEndMeeting, handleEndMeetingCancel, isEndMeetingModalOpen }) {

    const [loading, setLoading] = useState(false);
    const [isDisabled, setisDisabled] = useState(false);

    const handleEndMeetingClick = async () => {
        setLoading(true);
        setisDisabled(true);
        await handleEndMeeting();
        setLoading(false)
        setisDisabled(false);
    }

    return (
        <>
            <Dialog
                open={isEndMeetingModalOpen}
                onClose={() => { }}  // Empty function to ensure modal can't be closed by default actions
                PaperProps={{
                    style: {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        color: 'white',
                        borderRadius: '15px',
                        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.4)',
                    }
                }}
            >
                <DialogContent>
                    <Typography variant="h5" gutterBottom>
                        Interview Concluded ?
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Please click on 'End Interview' button once you are sure you have finished all the questions. Doing otherwise will reduce your chances of getting selected for the position.
                    </Typography>
                    <Typography variant="body2">
                        Are you sure?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        variant="outlined"
                        color="primary"
                        isDisabled={isDisabled}
                        onClick={handleEndMeetingCancel}>
                        Cancel
                    </LoadingButton>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        // isLoading={loading}
                        onClick={handleEndMeetingClick}>
                        {loading ? "Saving, Please wait..." : "Yes, End Interview"}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}