import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const dialogStyles = {
    title: {
        backgroundColor: '#f64f59', // Coral color for the title to grab attention but not too bright
        color: 'white',
        padding: '16px 24px', // Standard padding for Material-UI DialogTitle
        borderBottom: '1px solid #ffffff', // Adds a subtle separation from the content
    },
    content: {
        padding: '20px 24px', // Slightly more padding for content for a spacious feel
        color: '#000', // Darker text for readability
        backgroundColor: 'white', // Keep it simple and clean
    },
    actions: {
        padding: '16px 24px', // Consistent padding with the title
        borderTop: '1px solid #ddd', // Light line to separate actions from the content
        backgroundColor: 'white', // Match with content for a unified look
    },
    button: {
        color: '#12c2e9', // Use the light blue as the text color for the button for a subtle look
        fontWeight: 'bold', // Make text bold to stand out as a primary action
        '&:hover': {
            backgroundColor: 'rgba(18, 194, 233, 0.1)', // Light hover effect for interactivity
        },
    },
};

const ErrorDialog = ({ open, handleClose, errorMessage, handleRefresh }) => {
    return (
        <Dialog
            disableEscapeKeyDown
            open={open}
            aria-labelledby="error-dialog-title"
            aria-describedby="error-dialog-description"
        >
            <DialogTitle id="error-dialog-title" style={dialogStyles.title}>
                An Error Occurred
            </DialogTitle>
            <DialogContent style={dialogStyles.content}>
                <DialogContentText id="error-dialog-description">
                    {errorMessage || "Something went wrong. Please try again later."}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={dialogStyles.actions}>
                <Button onClick={handleClose} style={dialogStyles.button}>
                    Close
                </Button>
                <Button onClick={handleRefresh} style={dialogStyles.title}>
                    Refresh
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ErrorDialog;
