import { AppBar, Button, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import SettingsIcon from '@mui/icons-material/Settings';
import CallEndIcon from '@mui/icons-material/CallEnd';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIosTwoTone';
import { useEffect, useState } from 'react';
import SettingsDialog from './SettingsDialog';

export default function ({
    meetingOngoing,
    handleMicClick,
    isMuted,
    listening,
    handleVideoClick,
    isVideoOff,
    isSharingScreen,
    stopShareScreen,
    handleShareScreen,
    setIsExpanded,
    isExpanded,
    handleEndMeetingClick,
    handleNextClick,
    isNextButtonDisabled,
}) {
    const [secondsElapsed, setSecondsElapsed] = useState(0);
    const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);
    useEffect(() => {
        let timer;
        if (meetingOngoing) {
            timer = setInterval(() => {
                setSecondsElapsed((prevSeconds) => prevSeconds + 1);
            }, 1000);
        }

        return () => clearInterval(timer); // Cleanup on component unmount or when meeting stops
    }, [meetingOngoing]);

    const formatTime = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = seconds % 60;
        return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s
            .toString()
            .padStart(2, '0')}`;
    };

    function setSettingsClicked() {
        setSettingsModalOpen(true);
    }

    function handleSettingsCancel() {
        setSettingsModalOpen(false);
    }

    return (
        <>
            <AppBar
                position="fixed"
                color="inherit"
                style={{
                    top: 'auto',
                    bottom: 0,
                    background: 'rgba(0, 0, 0, 0.8)',
                    boxShadow: '0px -5px 15px rgba(0, 0, 0, 0.2)',
                    width: '100%',
                }}
            >
                <Toolbar>
                    <div
                        className="listeningStatus"
                        style={{ position: 'absolute', color: 'white' }}
                    >
                        <Tooltip arrow title="Nova is listening!">
                            {listening ? 'Listening...' : 'Not listening'}
                        </Tooltip>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                        {/* IconButton and Button components */}
                        <IconButton
                            className="mic"
                            onClick={handleMicClick}
                            style={{ color: isMuted && !listening ? '#e57373' : '#fff' }}
                        >
                            {isMuted && !listening ? <MicOffIcon /> : <MicIcon />}
                        </IconButton>
                        <IconButton
                            className="camera"
                            onClick={handleVideoClick}
                            style={{ color: isVideoOff ? '#e57373' : '#fff' }}
                        >
                            {isVideoOff ? <VideocamOffIcon /> : <VideocamIcon />}
                        </IconButton>
                        <IconButton
                            className="screenShare"
                            onClick={isSharingScreen ? stopShareScreen : handleShareScreen}
                            style={{ color: isSharingScreen ? '#81c784' : '#fff' }}
                        >
                            <ScreenShareIcon />
                        </IconButton>
                        {/* <IconButton onClick={setSettingsClicked} style={{ color: '#fff' }}>
                            <SettingsIcon />
                        </IconButton> */}
                        <Button
                            className="nextButton"
                            variant="contained"
                            color="info"
                            onClick={handleNextClick}
                            disabled={isNextButtonDisabled}
                            startIcon={<ArrowForwardIcon />}
                            sx={{
                                ml: 1,
                                '&.Mui-disabled': {
                                    backgroundColor: '#0f4a8d',
                                    color: '#cccccc',
                                    cursor: isNextButtonDisabled ? 'not-allowed' : 'pointer',
                                },
                            }}
                        >
                            Next
                        </Button>
                        <Button
                            className="endButton"
                            variant="contained"
                            color="primary"
                            onClick={handleEndMeetingClick}
                            startIcon={<CallEndIcon />}
                            sx={{ ml: 1 }}
                        >
                            End Interview
                        </Button>
                    </div>
                    <SettingsDialog
                        isSettingsModalOpen={isSettingsModalOpen}
                        handleSettingsCancel={handleSettingsCancel}
                    />
                    <Typography className='time' variant="h6" sx={{ color: '#E0E0E0' }}>
                        {formatTime(secondsElapsed)}
                    </Typography>
                </Toolbar>
            </AppBar>
        </>
    );
}
