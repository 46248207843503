import React, { useState, useEffect } from 'react';

const TypingEffectSmall = ({ message }) => {
    const [typedMessage, setTypedMessage] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);

    const preventCopy = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        if (currentIndex < message.length) {
            const timeoutId = setTimeout(() => {
                setTypedMessage(typedMessage + message[currentIndex]);
                setCurrentIndex(currentIndex + 1);
            }, 75);

            return () => clearTimeout(timeoutId);
        }
    }, [typedMessage, currentIndex, message]);

    // Effect to reset when message changes
    useEffect(() => {
        setTypedMessage('');
        setCurrentIndex(0);
    }, [message]);

    return (
        <div className="typing-effect-small" onCopy={preventCopy} onContextMenu={preventCopy}>
            {typedMessage}
            <span className="typing-cursor">|</span>
        </div>
    );
};

export default TypingEffectSmall;
