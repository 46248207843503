import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";

export function SilenceWarningDialog({ isModalOpen, handleYesClick, handleNoClick }) {
    return (
        <Dialog
            open={isModalOpen}
            onClose={() => { }}  // Empty function to ensure modal can't be closed by default actions
            PaperProps={{
                style: {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    color: 'white',
                    borderRadius: '15px',
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.4)',
                }
            }}
        >
            <DialogContent>
                <Typography variant="h5" gutterBottom>
                    Are you done with your answer?
                </Typography>
                <Typography variant="body1">
                    Please let us know if you have completed your response so we can proceed.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleNoClick}
                >
                    No
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleYesClick}
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}