import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogActions, Typography, List, ListItem, ListItemText, Grid, Divider, FormGroup, FormControlLabel, Switch, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import LoadingButton from '../LoadingButton';
import { dispatch } from '../../store';
import { setSelectedCam, setSelectedMic } from '../../store/slices/settingsSlice';

function SettingsDialog({ isSettingsModalOpen, handleSettingsCancel }) {
    const [selectedCategory, setSelectedCategory] = useState('camera');
    const [videoDevices, setVideoDevices] = useState([]);
    const [audioDevices, setAudioDevices] = useState([]);
    const [selectedCamera, setSelectedCamera] = useState('');
    const [selectedMicrophone, setSelectedMicrophone] = useState('');
    const [isSettingsOpen, setSettingsOpen] = useState(isSettingsModalOpen);

    const [settings, setSettings] = useState({
        camera: true,
        microphone: false,
        // Additional settings
    });

    // console.log("SettingsDialog", isSettingsModalOpen)
    // Function to handle the settings change
    const handleChange = (event) => {
        setSettings({ ...settings, [event.target.name]: event.target.value });
        if (event.target.name === "camera") {
            setSelectedCamera(event.target.value);
            dispatch(setSelectedCam(event.target.value))
        }
        else if (event.target.name === "microphone") {
            setSelectedMicrophone(event.target.value);
            dispatch(setSelectedMic(event.target.value))
        }
    };

    function handleCancel() {
        setSettingsOpen(false);
        handleSettingsCancel();
    }

    useEffect(() => {
        // Fetch available video and audio devices
        navigator.mediaDevices.enumerateDevices().then(devices => {
            const videoInputs = devices.filter(device => device.kind === 'videoinput');
            const audioInputs = devices.filter(device => device.kind === 'audioinput');

            setVideoDevices(videoInputs);
            setAudioDevices(audioInputs);

            if (videoInputs.length > 0) {
                setSelectedCamera(videoInputs[0].label);
            }

            if (audioInputs.length > 0) {
                setSelectedMicrophone(audioInputs[0].label);
            }
        });
    }, []);

    useEffect(() => {
        setSettingsOpen(isSettingsModalOpen);
    }, [isSettingsModalOpen])
    // console.log("videoInputs", audioDevices, selectedMicrophone);
    const renderSettings = (category) => {
        switch (category) {
            case 'camera':
                return (
                    <FormGroup>
                        <FormControl fullWidth>
                            <InputLabel sx={{ color: 'white' }} id="microphone-settings-label">Select Camera</InputLabel>
                            <Select
                                labelId="microphone-settings-label"
                                id="microphone-settings"
                                value={selectedCamera}
                                label="Camera"
                                onChange={handleChange}
                                name="camera"
                                sx={{ color: 'white', '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' }, '& .MuiSvgIcon-root': { color: 'white' } }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            bgcolor: 'rgba(0, 0, 0, 0.8)',
                                            color: 'white',
                                        },
                                    },
                                }}
                            >
                                {videoDevices.map((option) => (
                                    <MenuItem key={option.deviceId} value={option.deviceId}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </FormGroup>
                );
            case 'microphone':
                return (
                    <FormGroup>
                        <FormControl fullWidth>
                            <InputLabel sx={{ color: 'white' }} id="microphone-settings-label">Select Microphone</InputLabel>
                            <Select
                                labelId="microphone-settings-label"
                                id="microphone-settings"
                                value={selectedMicrophone}
                                label="Microphone"
                                onChange={handleChange}
                                name="microphone"
                                sx={{ color: 'white', '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' }, '& .MuiSvgIcon-root': { color: 'white' } }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            bgcolor: 'rgba(0, 0, 0, 0.8)',
                                            color: 'white',
                                        },
                                    },
                                }}
                            >
                                {audioDevices.map((option) => (
                                    <MenuItem key={option.deviceId} value={option.deviceId}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {/* Additional microphone settings */}
                    </FormGroup>
                );
            // Add cases for additional categories
            default:
                return null;
        }
    };

    return (
        <Dialog
            open={isSettingsOpen}
            onClose={handleCancel}
            fullWidth
            maxWidth="md"
            PaperProps={{
                style: {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    color: 'white',
                    borderRadius: '15px',
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.4)',
                }
            }}
        >
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <List component="nav">
                            <ListItem
                                button
                                selected={selectedCategory === 'camera'}
                                onClick={() => setSelectedCategory('camera')}
                                sx={selectedCategory === 'camera' ? { bgcolor: 'rgba(255, 255, 255, 0.2)' } : {}}
                            >
                                <ListItemText primary="Camera" />
                            </ListItem>
                            <ListItem
                                button
                                selected={selectedCategory === 'microphone'}
                                onClick={() => setSelectedCategory('microphone')}
                                sx={selectedCategory === 'microphone' ? { bgcolor: 'rgba(255, 255, 255, 0.2)' } : {}}
                            >
                                <ListItemText primary="Microphone" />
                            </ListItem>
                            {/* Add more categories as ListItems here */}
                        </List>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs={7}>
                        <Typography variant="h6" gutterBottom>
                            {`${selectedCategory.charAt(0).toUpperCase() + selectedCategory.slice(1)} Settings`}
                        </Typography>
                        {renderSettings(selectedCategory)}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton variant="outlined" onClick={handleCancel}>
                    Close
                </LoadingButton>
                {/* <LoadingButton variant="contained" onClick={handleSave}>
                    Save
                </LoadingButton> */}
            </DialogActions>
        </Dialog>
    );
}

export default SettingsDialog;
