import Dexie from 'dexie';
import { encrypt } from '../../utils';

const db = new Dexie("NH_IV");

db.version(1).stores({
    qas: '++id, type, blob, &question, hasAsked',
});

// Function to store an audio blob
async function storeAudioBlob(type, blob, question, hasAsked) {
    if (db) {
        try {
            const encQuestion = encrypt(question);
            // question = encQuestion.encryptedData + "::" + encQuestion.iv;
            await db.qas.add({ type, blob, question, hasAsked });
        } catch (error) {
            if (error.name === 'DatabaseClosedError') {
                // Attempt to re-open the database
                // Optionally, retry the operation or handle according to your app logic
            }
            else if (error.name === 'ConstraintError') {
                // Handle the duplicate question scenario here
            } else {
                // Handle other types of errors
                console.error('An error occurred:', error);
            }
        }
    }
}

// Function to retrieve an audio blob by id
async function getAudioBlob(id) {
    return await db.qas.get(id);
}

async function getQuestionByType(ques_type) {
    const matchingQuestions = await db.qas
        .where('type')
        .equals(ques_type)
        .first();
    return matchingQuestions;
}

async function updateQuestionBlobById(id, updatedValue) {
    try {
        const updatedCount = await db.qas.update(id, { blob: updatedValue });
        if (updatedCount === 1) {
            // console.log('The question was successfully updated.');
        }
    } catch (error) {
        console.error('Failed to update the question:', error);
    }
}

async function updateQuestionStatusById(id, updatedValue) {
    try {
        const updatedCount = await db.qas.update(id, { hasAsked: updatedValue });
        if (updatedCount === 1) {
            // console.log('The question was successfully updated.');
        }
    } catch (error) {
        console.error('Failed to update the question:', error);
    }
}

async function getNextQuestionId() {
    const questions = await db.qas
        .where('hasAsked').equals('false')
        .and(question => question.type !== 'interview_closing_message')
        .first();

    return questions;
}

async function getQuestionById(id) {
    const question = await db.qas.get(id);
    return question;
}

async function clearTable() {
    try {
        await db.qas.clear();
    } catch (error) {
        console.error("Failed to clear the table:", error);
    }
}

async function getPendingQuestions() {
    let unaskedquestions = await db.qas.where('hasAsked').equals("false")
        .and(question => question.type !== 'interview_closing_message')
        .and(question => question.type !== 'welcome_greeting')
        .count()
    return unaskedquestions;
}

async function getTotalQuestions() {
    let unaskedquestions = await db.qas.where('type').notEqual("interview_closing_message")
        .and(question => question.type !== 'welcome_greeting')
        .count()
    return unaskedquestions;
}

export {
    storeAudioBlob,
    getAudioBlob,
    getQuestionByType,
    updateQuestionBlobById,
    updateQuestionStatusById,
    getNextQuestionId,
    getQuestionById,
    // safelyDeleteDatabase,
    getPendingQuestions,
    getTotalQuestions,
    clearTable
}
