import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    Grid,
    Avatar,
    Typography,
    Box,
    CssBaseline,
    IconButton,
    Button,
    Tooltip,
} from '@mui/material';

import createSpeechServicesPonyfill from 'web-speech-cognitive-services';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import { decryptData, getNextQuestion, preProcessInterviewData } from '../utils';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { dispatch } from '../store';
import {
    addInterviewEvents,
    getQuestionVoice,
    postInterviewDetails,
    resetQuestions,
    setCommonErrors,
    setCurrentQuestion,
    setInterviewQA,
    setisVideoMeeting,
} from '../store/slices/interviewsSlice';
import RefreshDialog from '../components/meeting/RefreshDialog';
import EndMeetingWarningDialog from '../components/meeting/EndMeetingWarningDialog';
import MeetingWelcomeDialog from '../components/meeting/MeetingWelcomeDialog';
import MeetingAppBar from '../components/meeting/MeetingAppBar';
import Participants from '../components/meeting/Participants';
import cloneDeep from 'lodash/cloneDeep';
import TypingEffect from '../components/meeting/TypingEffect';
import userAvatar from '../assets/avatar_circle.png';
import AceEditorComponent from '../components/testing/aceeditorcomponent';
import PersonIcon from '@mui/icons-material/Person';
import MeetingDialog from '../components/meeting/MeetingDialog';
import MeetingProgressStepper from '../components/meeting/MeetingProgressStepper';
import HoverPopover from '../components/HoverPopover';
import useCameraObstructionDetector from '../components/hooks/useCameraObstructionDetector';
import MeetingNotifications from '../components/meeting/MeetingNotifications';
import useFullScreen from '../components/hooks/useFullScreen';
import useTabFocus from '../components/hooks/useTabFocus';
import {
    getNextQuestionById,
    getNextQuestionId,
    getQuestionById,
    getQuestionByType,
    getTotalQuestions,
    updateQuestionBlobById,
    updateQuestionStatusById,
} from '../store/doxie/doxieDb';
import { compressAndSaveVideo, saveVideoLocally } from '../utils/AVUtils';
import useVideoRecorder from '../components/hooks/useVideoRecorder';
import TourComponent from '../components/Tour';
import useScreenshotUploader from '../components/hooks/useScreenshotUploader';
import TypingEffectSmall from '../components/meeting/TypingEffectSmall';
import { SilenceWarningDialog } from '../components/meeting/SilenceWarningDialog';

const MemoizedVideoPreview = React.memo(({ videoStream }) => {
    const videoRef = React.useRef(null);

    React.useEffect(() => {
        if (videoRef.current && videoStream) {
            videoRef.current.srcObject = videoStream;
        }
    }, [videoStream]);

    return (
        <video
            ref={videoRef}
            autoPlay
            muted
            style={{
                width: '100%',
                objectFit: 'cover',
                height: '100%',
                borderRadius: '15px',
                backgroundColor: 'white',
            }}
        />
    );
});

const messages = [
    "Please say 'I am done' or click on 'NEXT' button below when you finish answering...",
    "Please do not cheat or act dishonestly during the interview. If you do, the interview will be rejected, and the HR will be informed.",
    "We use fraud analysis, plagiarism checks, iris detection, and many other methods to catch cheating during the interview."
];

function MeetingUI() {
    const commands = [
        {
            command: /I(\'m| am) done/,
            callback: () => {
                try {
                    handleTranscript();
                } catch (error) {
                    console.error('Error handling transcript:', error);
                }
            },
        },
    ];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currQuestionValue, setCurrQuestionValue] = useState('');
    let lastSpeakTimestamp = 0;
    const MIN_INTERVAL = 600;
    const [isActive, setIsActive] = useState(false);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [questionsDict, setQuestionsDict] = useState(null);
    const [questionsDictCopy, setQuestionsDictCopy] = useState(null);
    const [isMuted, setIsMuted] = useState(true);
    const [isVideoOff, setIsVideoOff] = useState(true);
    const [uniq_id, setUniqId] = useState(0);
    const [isExpanded, setIsExpanded] = useState(false);
    const [interviewInProgress, setInterviewInProgress] = useState(false);
    const [participants, setParticipants] = useState([{ name: 'Nova' }]);
    // const [isAdminSpeaking, setIsAdminSpeaking] = useState(false);
    const [isSharingScreen, setIsSharingScreen] = useState(false);
    const [meetingOngoing, setMeetingOngoing] = useState(false);
    const [isEndMeetingModalOpen, setIsEndMeetingModalOpen] = useState(false);
    const [message, setMessage] = useState('Tell me about yourself ?');
    const [greetings, setGreetings] = useState();
    const [isAudioRecording, setIsAudioRecording] = useState(false);
    const [stepProgress, setStepProgress] = useState(0);
    const audioRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const AUDIO_DBNAME = 'AudioDatabase';
    const AUDIO_DBVERSION = 3;
    const videoRef = useRef(null);
    const [isCodeEditorVisible, setCodeEditorVisible] = useState(false);
    const {
        interviewsData,
        interviewQA,
        currentQuestion,
        speechToken,
        codingError,
        questionVoiceUrl,
        interviewTokens,
    } = useSelector((state) => state.interviews);
    const { selectedCamera, selectedMicrophone } = useSelector((state) => state.settings);
    const speakerName = 'Nova';
    const [isMeetingBarVisible, setMeetingBarVisible] = useState(true);
    const { isFullScreen, toggleFullScreen } = useFullScreen();
    const { videoRefInt, canvasRefInt, obstructionDetected } = useCameraObstructionDetector();
    const { tabActive, windowFocused } = useTabFocus();
    const [nextQuestionId, setNextQuestionId] = useState();
    const [isLastQuestion, setIsLastQuestion] = useState(false);
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
    const [isButtonClickable, setIsButtonClickable] = useState(true);
    const [tourStates, setTourStates] = useState({
        isTourOpen: false,
        accentColor: '#5cb7b7',
    });
    const { captureRef, startScreenshotCapture, manualCapture } = useScreenshotUploader(10000, 1280, 720, "Meet");
    const [currentMessage, setCurrentMessage] = useState('');
    const [messageIndex, setMessageIndex] = useState(0);
    const [openSilenceDialog, setopenSilenceDialog] = useState(false);
    const [isSilenceRecordingEnabled, setisSilenceRecordingEnabled] = useState(false);

    useEffect(() => {
        let typingTimeout;

        const typeNextMessage = () => {
            setCurrentMessage('');
            typingTimeout = setTimeout(() => {
                setCurrentMessage(messages[messageIndex]);
            }, 500); // Delay before the next message starts typing
        };

        typeNextMessage(); // Trigger the typing effect for the first message

        return () => clearTimeout(typingTimeout); // Cleanup timeout on unmount
    }, [messageIndex]);

    const resetAndRetypeMessage = () => {
        setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    };

    const closeTour = () => {
        setTourStates((prev) => ({ ...prev, isTourOpen: false }));
        setIsModalOpen(true);
    };

    const openTour = () => {
        setTourStates((prev) => ({ ...prev, isTourOpen: true }));
    };

    useEffect(() => {
        const cleanup = startScreenshotCapture();
        return cleanup;
    }, [startScreenshotCapture]);

    useEffect(() => {
        openTour();
    }, []);

    const {
        isRecording,
        setIsRecording,
        startMeeting,
        endMeeting,
        isMeetingActive,
        mediaRecorderRef,
        videoStream,
        uploadProgress,
        isUploading,
    } = useVideoRecorder({ width: 640, height: 480, ivid: interviewsData[0]?.ivid });
    useEffect(() => {
        if (speechToken) initSpeechRecognitionPonyFill(speechToken);
    }, [speechToken]);

    // function getToken() {
    //     dispatch(getIntSpeechToken());
    // }

    // useEffect(() => {
    //     getToken();
    //     const interval = setInterval(getToken, 8 * 60 * 1000); // 8 minutes in milliseconds
    //     return () => clearInterval(interval);
    // }, [])

    // console.log("interviewTokens", interviewTokens?.user?.name);
    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } =
        useSpeechRecognition({ commands });


    const navigate = useNavigate();
    //handline video
    const chunksRef = useRef([]);
    const videoRefs = useRef([]);

    useEffect(() => {
        return () => {
            dispatch(resetQuestions());
        };
    }, []);

    // console.log("speechtoken", speechToken)
    function initSpeechRecognitionPonyFill(stoken) {
        try {
            // const { SpeechRecognition: AzureSpeechRecognition } = createSpeechServicesPonyfill({
            //     credentials: {
            //         region: "centralindia",
            //         authorizationToken: stoken,
            //     }
            // });
            // SpeechRecognition.applyPolyfill(AzureSpeechRecognition);
        } catch (error) {
            console.error('Error initializing AzureSpeechRecognition:', error);
        }
    }

    useEffect(() => {
        if (interviewsData) {
            if (interviewsData[0]?.meta) {
                let int_questions = preProcessInterviewData(interviewsData[0]?.meta);
                // setGreetings(int_questions?.greetings);
                // setQuestionsDict(cloneDeep(int_questions?.questions));
                setQuestionsDictCopy(cloneDeep(int_questions?.questions));
                getTotalQuestions().then((result) => {
                    setTotalQuestions(result);
                    // console.log("setTotalQuestions", result);
                });
                setUniqId(interviewsData[0]?.ivid);
            } else {
                dispatch(setCommonErrors('Job data or meta data not valid/available '));
                navigate('/meetingerror');
            }
        }
    }, [interviewsData]);

    // console.log(uniq_id);
    const handleTranscript = async () => {
        if (interviewInProgress) {
            const finatrans = transcript;
            setisSilenceRecordingEnabled(false);
            setMessage('');
            dispatch(
                setInterviewQA({ message: finatrans, speaker: 2, messageid: currentQuestion })
            );
            stopListeningToMic();
            setTimeout(() => {
                dispatch(setCurrentQuestion(1));
                speakInterviewQuestion();
                resetTranscript();
            }, 2000);
            setStepProgress(stepProgress + 1);
        }
    };

    function getNextQuestionTTS() {
        getNextQuestionId()
            .then(async (results) => {
                // console.log("first_question", results);
                if (results) {
                    let blob = await dispatch(getQuestionVoice(results.question));
                    await updateQuestionBlobById(results.id, blob);
                    setNextQuestionId(results.id);
                } else if (!isLastQuestion) {
                    let question = await getQuestionByType('interview_closing_message');
                    if (question) {
                        let blob = await dispatch(getQuestionVoice(question.question));
                        await updateQuestionBlobById(question.id, blob);
                        setNextQuestionId(question.id);
                        setIsLastQuestion(true);
                    }
                }
            })
            .catch((error) => {
                throw new Error(
                    'Interview is not ready!!. Please reach to customer support. ' + error
                );
            });
    }

    // console.log("intstatus", questionsDict);
    async function speakWelcomeQuestion() {
        const voices = window.speechSynthesis.getVoices();
        let question = await getQuestionByType('welcome_greeting');
        if (question) {
            speakQuestion(question.blob, question.question);
            updateQuestionStatusById(question.id, true);
            getNextQuestionTTS();
        }
    }

    async function speakInterviewQuestion() {
        let question = await getQuestionById(nextQuestionId);
        if (question) {
            if (question.type === 'coding questions') {
                setTimeout(() => {
                    setCodeEditorVisible(true);
                }, 4000);
            } else {
                setCodeEditorVisible(false);
            }
            setCurrQuestionValue(question.question);
            dispatch(
                setInterviewQA({
                    message: question.question,
                    speaker: 1,
                    messageid: currentQuestion,
                })
            );
            dispatch(
                addInterviewEvents(interviewsData[0]?.ivid, 4, JSON.stringify(question.question))
            );
            if (question.blob) {
                speakQuestion(question.blob, question.question);
            } else {
                speakQuestionFromMic(question.question);
            }
            updateQuestionStatusById(question.id, 'true');
            if (!isLastQuestion) {
                getNextQuestionTTS();
            }
        }
    }

    function stopCurrentAudio() {
        if (audioObj && !audioObj.paused) {
            audioObj.pause();
            audioObj.currentTime = 0;
        }
    }

    function speakQuestion(questionBlob, question) {
        stopCurrentAudio();
        const newSrc = URL.createObjectURL(questionBlob);

        if (!audioObj) {
            audioObj = new Audio(newSrc);
            audioObj.onplay = function () {
                setMessage(question);
                resetAndRetypeMessage();
                setIsActive(true);
                setIsNextButtonDisabled(true);
            };
            audioObj.addEventListener('ended', () => {
                resetTranscript();
                handleMicClick(true);
                setIsActive(false);
                setIsNextButtonDisabled(false);
                setisSilenceRecordingEnabled(true);
                startSilenceTimeout();
                if (isLastQuestion) {
                    setTimeout(() => {
                        handleEndMeetingClick();
                    }, 3000);
                }
            });

            audioObj.addEventListener('error', (e) => {
                console.error('Error occurred while playing audio:', e);
            });
        } else {
            audioObj.src = newSrc;
        }

        if (!audioObj.paused || audioObj.readyState < 3) {
            const playWhenReady = () => {
                audioObj.play().catch((e) => console.error('Error playing audio:', e));
                audioObj.removeEventListener('loadeddata', playWhenReady);
            };
            audioObj.addEventListener('loadeddata', playWhenReady);
        } else {
            audioObj.play().catch((e) => console.error('Error playing audio:', e));
        }
    }

    function getNextQuestionForInterview() {
        if (interviewInProgress) {
            getNextQuestion(questionsDict).then((questions) => {
                if (questions.category === 'complete') {
                    setCodeEditorVisible(false);
                    speakQuestion(
                        greetings?.closing +
                        ". Your interview has been completed. Please click on 'End Interview' button below"
                    );
                    setInterviewInProgress(false);
                } else {
                    if (questions.category === 'coding') {
                        setTimeout(() => {
                            setCodeEditorVisible(true);
                        }, 4000);
                    } else {
                        setCodeEditorVisible(false);
                    }
                    setCurrQuestionValue(questions.ques);
                    dispatch(
                        setInterviewQA({
                            message: questions.ques,
                            speaker: 1,
                            messageid: currentQuestion,
                        })
                    );
                    dispatch(
                        addInterviewEvents(interviewsData[0]?.ivid, 4, JSON.stringify(questions))
                    );
                    speakQuestion(questions.ques);
                }
            });
        }
    }

    let audioObj;
    function speakQuestionFromMic(questions, override = false) {
        if (questions) {
            setIsActive(true);
            const now = Date.now();

            // Function to handle speech synthesis
            const handleSpeechSynthesis = () => {
                const voices = window.speechSynthesis.getVoices();
                // console.log("Available Voices:", voices);

                const selectedVoice = voices.find(
                    (v) => v.name === 'Microsoft David - English (United States)'
                );
                // console.log("Available Voices:", selectedVoice);
                const utterance = new SpeechSynthesisUtterance(questions);

                if (selectedVoice) {
                    utterance.voice = selectedVoice;
                } else {
                    console.warn('Preferred voice not found, using default');
                    utterance.voice = voices[0]; // or any default you prefer
                }
                utterance.rate = 1.5;
                utterance.pitch = 2;
                utterance.onend = async function (event) {
                    resetTranscript();
                    handleMicClick(true);
                    setIsActive(false);
                };
                if (override) {
                    window.speechSynthesis.cancel();
                    window.speechSynthesis.speak(utterance);
                } else {
                    if (now - lastSpeakTimestamp > MIN_INTERVAL) {
                        lastSpeakTimestamp = now;
                        setMessage(questions);
                        resetAndRetypeMessage();
                        window.speechSynthesis.cancel();
                        window.speechSynthesis.speak(utterance);
                    }
                }
            };

            // Check if voices are already loaded
            if (speechSynthesis.getVoices().length > 0) {
                handleSpeechSynthesis();
            } else {
                // Listen for the 'voiceschanged' event
                window.speechSynthesis.addEventListener('voiceschanged', handleSpeechSynthesis, {
                    once: true,
                });
            }
        }
    }

    let startListening;
    const silenceTimeout = { current: null };
    let isSilent = false;
    const startListeningToMic = async () => {
        // console.log('startListeningToMic', SpeechRecognition, listening);
        startListening = await SpeechRecognition.startListening({
            continuous: true,
            language: 'en-US',
        });
        const recognition = SpeechRecognition.getRecognition();

        // recognition.onstart = () => {
        //     console.log('Speech recognition started');
        // };

        recognition.onerror = (event) => {
            console.error('Speech recognition error:', event.error);
        };

        recognition.onresult = (event) => {
            console.log("recognition.onresult")
            clearTimeout(silenceTimeout.current); // Reset timer on speech detected
            isSilent = false;

            // Set timeout to detect silence
            startSilenceTimeout();
        };
    };

    function startSilenceTimeout() {
        silenceTimeout.current = setTimeout(() => {
            isSilent = true;
            // setopenSilenceDialog(true)
        }, 10000);
    }

    // Method to stop the silence timeout
    function stopSilenceTimeout() {
        clearTimeout(silenceTimeout.current);
    }

    const stopListeningToMic = async () => {
        // console.log("stopListeningToMic", listening);
        if (listening) {
            const recognition = SpeechRecognition.getRecognition();
            clearTimeout(silenceTimeout.current);
            if (recognition) recognition.continuous = false;
            await SpeechRecognition.abortListening();
        }
    };

    const handleMicClick = (override) => {
        setIsMuted((prevMuted) => {
            if (override) {
                prevMuted = override;
            }
            if (prevMuted) {
                startListeningToMic();
                startAudioRecording();
            } else {
                stopListeningToMic();
                // stopAudioRecording();
            }
            return !prevMuted;
        });
    };

    const handleNextClick = () => {
        if (isButtonClickable) {
            setIsButtonClickable(false);
            handleTranscript();
            setTimeout(() => {
                setIsButtonClickable(true);
            }, 1000);
        }
    };

    const handleVideoClick = () => {
        setIsVideoOff((prevVideoOff) => {
            if (prevVideoOff) {
                setIsRecording(true);
                startMeeting();
            }
            return !prevVideoOff;
        });
    };

    useEffect(() => {
        let interval;
        if (isActive) {
            interval = setInterval(() => {
                setIsSpeaking((prev) => !prev);
            }, 500);
        }

        return () => {
            clearInterval(interval);
        };
    }, [isActive]);

    const handleShareScreen = async () => {
        if (isSharingScreen) {
            stopShareScreen();
        } else {
            try {
                const stream = await navigator.mediaDevices.getDisplayMedia({ video: true });
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                } else {
                    // Delay the assignment by 500ms
                    setTimeout(() => {
                        if (videoRef.current) {
                            videoRef.current.srcObject = stream;
                        } else {
                            console.error('Video reference is still not available after delay.');
                        }
                    }, 500);
                }

                setIsSharingScreen(true);
            } catch (err) {
                console.error('Error sharing screen:', err);
                setIsSharingScreen(false);
            }
        }
    };

    const stopShareScreen = () => {
        if (videoRef.current && videoRef.current.srcObject) {
            const tracks = videoRef.current.srcObject.getTracks();
            tracks.forEach((track) => track.stop());
            videoRef.current.srcObject = null;
        }
        setIsSharingScreen(false);
    };

    const handleInitWarningModelClick = () => {
        dispatch(addInterviewEvents(interviewsData[0]?.ivid, 1));
        toggleFullScreen();
        setMeetingOngoing(true);
        handleVideoClick();
        setParticipants([...participants, { name: interviewsData[0].cand_name }]);
        setIsModalOpen(false);
        setInterviewInProgress(true);
        speakWelcomeQuestion();
        manualCapture();
    };

    const handleEndMeeting = useCallback(async () => {
        if (interviewsData && interviewQA) {
            window.speechSynthesis.cancel();
            await stopRecordings();
            const finalpayload = {
                status: 3,
                cand_status: 7,
                candid: interviewsData[0]?.candid,
                metas: JSON.stringify(interviewQA),
                questions: JSON.stringify(questionsDictCopy),
            };
            dispatch(addInterviewEvents(interviewsData[0]?.ivid, 2));

            await dispatch(postInterviewDetails(finalpayload, interviewsData[0]?.ivid));
            await endMeeting();

            setIsEndMeetingModalOpen(false);
            setMeetingOngoing(false);
            navigate('/thankyou', { replace: true });
        }
    }, [interviewsData, interviewQA, endMeeting, dispatch, navigate]);

    const memoizedVideoPreview = useMemo(
        () => <MemoizedVideoPreview videoStream={videoStream} />,
        [videoStream]
    );

    const handleEndMeetingCancel = async () => {
        setIsEndMeetingModalOpen(false);
    };

    const handleEndMeetingClick = async () => {
        // stopRecordings();
        await manualCapture();
        setIsEndMeetingModalOpen(true);
    };

    const stopRecordings = async () => {
        stopListeningToMic();
        await stopAudioRecording();
        await setIsRecording(false);
    };

    async function getDevices() {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter((device) => device.kind === 'videoinput');
        const audioDevices = devices.filter((device) => device.kind === 'audioinput');
        return { videoDevices, audioDevices };
    }

    async function startMediaStream(videoDeviceId, audioDeviceId) {
        const constraints = {
            video: { deviceId: videoDeviceId ? { exact: videoDeviceId } : undefined },
            audio: { deviceId: audioDeviceId ? { exact: audioDeviceId } : undefined },
        };
        return await navigator.mediaDevices.getUserMedia(constraints);
    }
    // console.log("selectedCamera", selectedCamera);

    // const saveVideoLocally = async (newBlob) => {
    //     const request = indexedDB.open("VideoDatabase", 1);
    //     request.onupgradeneeded = function (event) {
    //         const db = event.target.result;
    //         if (!db.objectStoreNames.contains('videos')) {
    //             db.createObjectStore("videos");
    //         }
    //     };

    //     request.onsuccess = function (event) {
    //         const db = event.target.result;
    //         const transaction = db.transaction(["videos"], "readwrite");
    //         const store = transaction.objectStore("videos");
    //         const getExistingVideo = store.get(uniq_id); // Using a consistent key

    //         getExistingVideo.onsuccess = function () {
    //             const existingBlob = getExistingVideo.result;
    //             let combinedBlob;
    //             if (existingBlob) {
    //                 combinedBlob = new Blob([existingBlob, newBlob], { type: 'video/mp4' });
    //             } else {
    //                 combinedBlob = newBlob;
    //             }

    //             const putRequest = store.put(combinedBlob, uniq_id);

    //             putRequest.onsuccess = function () {
    //                 console.log("Video saved to IndexedDB successfully.");
    //             };

    //             putRequest.onerror = function (event) {
    //                 console.error("Error saving video blob:", event.target.error);
    //             };
    //         };

    //         getExistingVideo.onerror = function (event) {
    //             console.error("Error fetching existing video blob:", event.target.error);
    //         };

    //         transaction.oncomplete = function () {
    //             console.log("Transaction completed successfully.");
    //         };

    //         transaction.onerror = function (event) {
    //             console.error("Transaction error:", transaction.error);
    //         };
    //     };

    //     request.onerror = function (event) {
    //         console.error("Error opening IndexedDB:", event.target.error);
    //     };
    // };

    //audio recording code
    const startAudioRecording = async () => {
        if (isAudioRecording) return;

        try {
            const constraints = {
                audio: {
                    echoCancellation: true,
                    noiseSuppression: true,
                    autoGainControl: true,
                },
            };
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            audioRecorderRef.current = new MediaRecorder(stream);
            audioRecorderRef.current.ondataavailable = (event) => {
                audioChunksRef.current.push(event.data);
            };
            audioRecorderRef.current.onstop = () => {
                saveAudioChunks()
                    .then((result) => {
                        // console.log(result);
                        // Handle the successful saving of audio chunks
                        // You can update the state or UI here if needed
                    })
                    .catch((error) => {
                        console.error(error);
                        // Handle any errors that occurred during the saving process
                        // This is where you might show an error message to the user
                    });
            };
            setIsAudioRecording(true);
            audioRecorderRef.current.start();
        } catch (error) {
            console.error('Error starting the recording:', error);
            // console.log("startAudioRecording 4", audioRecorderRef);
            alert(
                "Failed to start recording. Please ensure you've granted the necessary permissions."
            );
        }
    };

    const stopAudioRecording = () => {
        setIsAudioRecording(false);
        if (audioRecorderRef.current) {
            audioRecorderRef.current.stop();
        }
    };

    const saveAudioChunks = () => {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(AUDIO_DBNAME, AUDIO_DBVERSION);

            request.onupgradeneeded = function (event) {
                const db = event.target.result;
                if (!db.objectStoreNames.contains('audioChunks')) {
                    db.createObjectStore('audioChunks', { keyPath: 'id' });
                }
            };

            request.onerror = function (event) {
                console.error('Error opening the database:', event.target.errorCode);
                reject('Failed to open database');
            };

            request.onsuccess = function (event) {
                const db = event.target.result;
                const transaction = db.transaction(['audioChunks'], 'readwrite');
                const store = transaction.objectStore('audioChunks');

                transaction.onerror = function (event) {
                    console.error('Transaction error:', event.target.error);
                    reject('Transaction failed');
                };

                // Data validation
                if (!audioChunksRef.current || audioChunksRef.current.length === 0) {
                    reject('No audio chunks to save');
                    return;
                }

                store.add({
                    id: new Date().toISOString(),
                    chunks: audioChunksRef.current,
                }).onsuccess = function () {
                    audioChunksRef.current = [];
                    resolve('Audio chunks saved successfully');
                };
            };
        });
    };

    const handleEditorSubmit = () => {
        handleTranscript();
        setCodeEditorVisible(false);
    };

    const handleEditorSave = () => {
        // if (codingError)
        // speakQuestion("Your program has been saved!", true)
        // else
        //     speakQuestion("There was an error in saving your program, kindly try after sometime!", true)
    };

    const handleExited = () => {
        setCodeEditorVisible(false);
    };

    useEffect(() => {
        setMeetingBarVisible(!isCodeEditorVisible);
    }, [isCodeEditorVisible]);

    useEffect(() => {
        // console.log("isFullScreen", isFullScreen);
        dispatch(
            addInterviewEvents(interviewsData[0]?.ivid, 5, isFullScreen ? 'ENABLED' : 'DISABLED')
        );
    }, [isFullScreen]);

    function handleSilenceNoClick() {
        setopenSilenceDialog(false);
    }

    function handleSilenceYesClick() {
        setopenSilenceDialog(false);
        handleTranscript();
    }

    useEffect(() => {
        // console.log("isFullScreen", isFullScreen);
        dispatch(
            addInterviewEvents(
                interviewsData[0]?.ivid,
                6,
                obstructionDetected ? 'DISABLED' : 'ENABLED'
            )
        );
    }, [obstructionDetected]);

    useEffect(() => {
        dispatch(
            addInterviewEvents(
                interviewsData[0]?.ivid,
                8,
                tabActive && windowFocused ? 'ACTIVE' : 'INACTIVE'
            )
        );
    }, [tabActive, windowFocused]);

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(addInterviewEvents(interviewsData[0]?.ivid, 3));
        }, 180000);
        return () => clearInterval(interval);
    }, []);

    // console.log("sizeee", Object.keys(questionsDictCopy).length, stepProgress)
    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }
    return (
        <div ref={captureRef} style={{ padding: "1px", backgroundColor: "lightgrey" }}>
            <div
                className="interface"
                style={{
                    height: '100vh',
                    background: 'linear-gradient(120deg, #12c2e9, #c471ed, #f64f59)',
                }}
            >
                <TourComponent closeTour={closeTour} tourStates={tourStates} />
                <CssBaseline />
                <Grid container style={{ height: '100%' }}>
                    <Grid item xs={9} style={{ height: '100%' }}>
                        <Grid container style={{ height: '100%' }}>
                            <Grid item xs={12} style={{ height: '7%', padding: '8px' }}>
                                <div
                                    style={{
                                        background: 'rgba(255, 255, 255, 0.2)',
                                        backdropFilter: 'blur(5px)',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        border: '1px solid rgba(255, 255, 255, 0.3)',
                                    }}
                                >
                                    <Grid container style={{ height: '100%' }}>
                                        <Grid item xs={9}>
                                            {interviewsData[0]?.title && (
                                                <Typography
                                                    className="jobTitle"
                                                    variant="h5"
                                                    style={{
                                                        color: '#001800',
                                                        textAlign: 'left',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {interviewsData[0]?.title} Interview with{' '}
                                                    {interviewsData[0]?.Company}
                                                </Typography>
                                            )}
                                            {interviewsData[0]?.Job_Title_Cand && (
                                                <Typography
                                                    className="jobTitle"
                                                    variant="h5"
                                                    style={{
                                                        color: '#001800',
                                                        textAlign: 'left',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {interviewsData[0]?.Job_Title_Cand} Interview with{' '}
                                                    {interviewTokens?.user?.name}
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={3} style={{}}>
                                            <video
                                                ref={videoRefInt}
                                                style={{ display: 'none' }}
                                            ></video>
                                            <canvas
                                                ref={canvasRefInt}
                                                style={{ display: 'none' }}
                                            ></canvas>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={12} style={{ height: '5%', paddingLeft: '8px' }}>
                                <MeetingNotifications
                                    isVideoOff={isVideoOff}
                                    obstructionDetected={obstructionDetected}
                                    isFullScreen={isFullScreen}
                                    toggleFullScreen={toggleFullScreen}
                                    handleVideoClick={handleVideoClick}
                                    windowFocused={windowFocused}
                                    tabActive={tabActive}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ height: '88%' }}>
                                {isSharingScreen ? (
                                    <video
                                        ref={videoRef}
                                        autoPlay
                                        muted
                                        style={{
                                            width: '100%',
                                            objectFit: 'cover',
                                            height: '100%',
                                            borderRadius: '15px',
                                            backgroundColor: 'white',
                                        }}
                                    />
                                ) : isCodeEditorVisible ? (
                                    <AceEditorComponent
                                        handleEditorSubmit={handleEditorSubmit}
                                        handleEditorSave={handleEditorSave}
                                        show={isCodeEditorVisible}
                                        onExited={handleExited}
                                        currentQuestion={currQuestionValue}
                                    />
                                ) : (
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        height="100%"
                                    >
                                        <Tooltip
                                            arrow
                                            title="I am Nova! your AI Intelligent Interviewer."
                                        >
                                            <Avatar
                                                className="aiInterviewer"
                                                sx={{
                                                    width: isSpeaking ? 220 : 200,
                                                    height: isSpeaking ? 220 : 200,
                                                    transition: 'all 0.5s ease-in-out',
                                                    backgroundColor: 'transparent',
                                                    boxShadow: 3,
                                                    marginBottom: '10px',
                                                    backgroundImage: `url(${userAvatar})`,
                                                    backgroundPosition: 'center',
                                                    backgroundSize: 'cover',
                                                    backgroundRepeat: 'no-repeat',
                                                    borderRadius: '50%',
                                                }}
                                            ></Avatar>
                                        </Tooltip>
                                        <Typography variant="h6" sx={{ color: 'white' }}>
                                            {speakerName}
                                        </Typography>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>

                        {isMeetingBarVisible && (
                            <MeetingAppBar
                                meetingOngoing={meetingOngoing}
                                handleMicClick={handleMicClick}
                                isMuted={isMuted}
                                listening={listening}
                                handleVideoClick={handleVideoClick}
                                isVideoOff={isVideoOff}
                                isSharingScreen={isSharingScreen}
                                stopShareScreen={stopShareScreen}
                                handleShareScreen={handleShareScreen}
                                setIsExpanded={setIsExpanded}
                                isExpanded={isExpanded}
                                handleEndMeetingClick={handleEndMeetingClick}
                                handleNextClick={handleNextClick}
                                isNextButtonDisabled={isNextButtonDisabled}
                            />
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        style={{
                            height: 'calc(100% - 64px)',
                            background: 'rgba(0, 0, 0, 0.8)',
                            boxShadow: '0px -5px 15px rgba(0, 0, 0, 0.2)',
                        }}
                    >
                        <Grid container style={{ height: '100%' }}>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    height: '40%',
                                    padding: '8px',
                                    borderBottom: '2px solid rgba(0, 0, 0, 1)',
                                }}
                            >
                                <Box
                                    style={{
                                        flexDirection: 'column',
                                        height: '100%',
                                        display: 'flex',
                                        background:
                                            'linear-gradient(to bottom, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%)',
                                        boxShadow: 'inset 0px -5px 15px rgba(0, 0, 0, 0.2)',
                                        padding: '10px',
                                        borderRadius: '8px',
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        style={{
                                            color: '#FFFFFF',
                                            textAlign: 'center',
                                            borderBottom: '1px solid rgba(0, 0, 0, 1)',
                                        }}
                                    >
                                        {participants[0]?.name}
                                    </Typography>
                                    <TypingEffect message={message} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} style={{
                                height: '10%',
                                padding: '8px',
                                borderBottom: '2px solid rgba(0, 0, 0, 1)',
                            }}>
                                <TypingEffectSmall message={currentMessage} />
                            </Grid>
                            <Grid item xs={12} style={{ height: '45%', margin: '2px' }}>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    height="100%"
                                    className="videoPreview"
                                >
                                    <Typography
                                        variant="h6"
                                        style={{ color: '#FFFFFF', textAlign: 'center' }}
                                    >
                                        {participants[1]?.name}
                                    </Typography>
                                    {isVideoOff ? (
                                        <Avatar
                                            style={{
                                                width: '200px',
                                                height: '200px',
                                                marginBottom: '10px',
                                                backgroundColor: '#e91e63',
                                                boxShadow: '0px 0px 10px rgba(233, 30, 99, 0.5)',
                                            }}
                                        >
                                            <PersonIcon fontSize="large" />
                                        </Avatar>
                                    ) : (
                                        memoizedVideoPreview
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12} style={{ height: '5%' }}>
                                <HoverPopover
                                    popoverContent={<Typography>Interview Progress</Typography>}
                                >
                                    <MeetingProgressStepper
                                        totalSteps={totalQuestions}
                                        currentStep={stepProgress - 1}
                                    />
                                </HoverPopover>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <MeetingWelcomeDialog
                    handleInitWarningModelClick={handleInitWarningModelClick}
                    isModalOpen={isModalOpen}
                />

                <SilenceWarningDialog isModalOpen={openSilenceDialog} handleNoClick={handleSilenceNoClick} handleYesClick={handleSilenceYesClick} />

                <EndMeetingWarningDialog
                    handleEndMeeting={handleEndMeeting}
                    handleEndMeetingCancel={handleEndMeetingCancel}
                    isEndMeetingModalOpen={isEndMeetingModalOpen}
                />

                <RefreshDialog />
                <style>
                    {`
          @keyframes vibrate {
            0% { transform: translate(-50%, -50%) scale(1); }
            50% { transform: translate(-50%, -50%) scale(1.05); }
            100% { transform: translate(-50%, -50%) scale(1); }
          }
          .typing-effect {
            font-size: 1.2em;
            white-space: nowrap;
            overflow: hidden;
            display: inline-block;
            word-wrap: break-word;
            white-space: normal;
            max-width: 100%;
            overflow-y: auto;
            color: rgba(255, 255, 255, 0.5);
            user-select: none;
          }
          .typing-effect-small {
            font-size: 1em;
            white-space: nowrap;
            overflow: hidden;
            display: inline-block;
            word-wrap: break-word;
            white-space: normal;
            max-width: 100%;
            overflow-y: auto;
            color: rgba(255, 255, 255, 0.5);
            user-select: none;
          }
          .typing-cursor {
            animation: blink 1s infinite;
          }

          @keyframes blink {
            0% { opacity: 1; }
            50% { opacity: 0; }
            100% { opacity: 1; }
          }
        `}
                </style>
            </div>
        </div >
    );
}

export default MeetingUI;
