import { Avatar, Collapse, List, ListItem, Typography } from "@mui/material";
import Draggable from "react-draggable";
import PersonIcon from '@mui/icons-material/Person';


export default function Participants({ isExpanded, participants, isVideoOff, videoRefs }) {
    return (
        <>
            {/* Participants Window */}
            <Draggable>
                <div style={{
                    position: 'absolute',
                    top: '20%',
                    right: '1%',
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    borderRadius: '15px',
                    overflow: 'hidden',
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.4)',
                    maxWidth: '500px'  // Increased width
                }}>
                    {/* ... Other UI elements ... */}
                    <Collapse in={isExpanded}>
                        <List>
                            {participants.map((item, index) => (
                                <ListItem key={index} style={{
                                    padding: '30px 30px',  // Increased padding for larger display
                                    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.05)'
                                    },
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}>
                                    {
                                        index === 1 ? (
                                            isVideoOff ? (
                                                <Avatar style={{
                                                    width: '150px',   // Increased size
                                                    height: '150px',
                                                    marginBottom: '10px',
                                                    backgroundColor: '#e91e63',
                                                    boxShadow: '0px 0px 10px rgba(233, 30, 99, 0.5)'
                                                }}>
                                                    <PersonIcon fontSize="large" />
                                                </Avatar>
                                            ) : (
                                                <video ref={el => videoRefs.current[0] = el} autoPlay muted style={{ width: '150px', height: '150px', borderRadius: '15px', marginBottom: '10px' }}></video>
                                            )
                                        ) : (
                                            <Avatar style={{
                                                width: '150px',   // Increased size
                                                height: '150px',
                                                marginBottom: '10px',
                                                backgroundColor: '#e91e63',
                                                boxShadow: '0px 0px 10px rgba(233, 30, 99, 0.5)'
                                            }}>
                                                <PersonIcon fontSize="large" />
                                            </Avatar>
                                        )
                                    }
                                    <Typography variant="body1" style={{ color: '#fff' }}>{item.name}</Typography>
                                </ListItem>
                            ))}
                        </List>
                    </Collapse>
                </div>
            </Draggable>
        </>
    )
}