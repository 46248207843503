import { createSlice } from '@reduxjs/toolkit';
import axios, { setAxiosToken } from '../../utils/axios';
import { setToken } from '../../utils/tokenStorage';
import s3Axios from '../../utils/s3Axios';

// initial state
const initialState = {
    interviewsData: null,
    interviewTokens: null,
    error: null,
    codingError: null,
    isLoading: false,
    interviewQA: [],
    currentQuestion: 0,
    isVideoMeeting: false,
    isVideoUploaded: false,
    isAudioUploaded: false,
    currentInterviewId: null,
    postInterviewResponse: null,
    commonError: null,
    speechToken: null,
    currentProgram: null,
    currentProgramId: null,
    questionVoiceUrl: null,
};

const interviews = createSlice({
    name: 'interviews',
    initialState,
    reducers: {
        // set interviewsList
        setInterviewResponse(state, action) {
            state.isLoading = false;
            state.interviewsData = action.payload;
            state.currentQuestion = 0;
            state.error = null;
        },

        setCurrentProgramDetails(state, action) {
            state.isLoading = false;
            state.currentProgramId = action.payload.id_str;
            state.currentProgram = action.payload;
            state.codingError = null;
        },

        resetCurrentProgramDetails(state) {
            state.currentProgramId = null;
            state.currentQuestion = null;
            state.codingError = null;
            state.isLoading = false;
        },

        setSpeechTokenResponse(state, action) {
            state.isLoading = false;
            state.speechToken = action.payload;
        },

        setInterviewTokenResponse(state, action) {
            state.isLoading = false;
            state.interviewTokens = action.payload;
            state.currentQuestion = 0;
        },

        setLoading(state, action) {
            state.isLoading = true;
        },

        setCommonErrors(state, action) {
            state.commonError = action.payload;
            state.isLoading = false;
        },

        resetQuestions: (state) => {
            state.interviewQA = [];
            state.currentQuestion = 0;
            state.isLoading = false;
        },

        setPostInterviewSuccess: (state, action) => {
            state.postInterviewResponse = action.payload;
            state.isLoading = false;
        },

        setInterviewQA(state, action) {
            state.interviewQA.push(action.payload);
            state.isLoading = false;
        },

        setCurrentQuestion(state, action) {
            state.currentQuestion += action.payload;
            state.isLoading = false;
        },

        setisVideoMeeting(state, action) {
            state.isVideoMeeting = action.payload;
            state.isLoading = false;
        },

        setCurrentMeetingId(state, action) {
            state.currentInterviewId = action.payload;
            state.isLoading = false;
        },

        // has error
        hasError(state, action) {
            state.error = action.payload;
            state.isLoading = false;
        },

        hasCodingError(state, action) {
            state.codingError = action.payload;
            state.isLoading = false;
        },

        uploadMeetingVideoSuccess(state) {
            state.isVideoUploaded = true;
            state.isLoading = false;
        },

        uploadMeetingAudioSuccess(state) {
            state.isAudioUploaded = true;
            state.isLoading = false;
        },

        addInterviewEventSuccess(state) {
            state.isLoading = false;
        },

        getQuestionVoiceSuccess(state, action) {
            state.questionVoiceUrl = action.payload;
        },

        resetAllInterviewState(state) {
            state.interviewQA = [];
            state.currentQuestion = 0;
            state.interviewsData = null;
            state.interviewTokens = null;
            state.error = null;
            state.isLoading = false;
            state.interviewQA = [];
            state.currentQuestion = 0;
            state.isVideoMeeting = false;
            state.isVideoUploaded = false;
            state.currentInterviewId = null;
            state.postInterviewResponse = null;
            state.commonError = null;
            state.isAudioUploaded = false;
            state.currentProgram = null;
            state.currentProgramId = null;
            state.codingError = null;
        }
    }
});

export const {
    setInterviewResponse,
    hasError,
    setLoading,
    setInterviewQA,
    setCurrentQuestion,
    setInterviewTokenResponse,
    setPostInterviewSuccess,
    resetAllInterviewState,
    setisVideoMeeting,
    uploadMeetingVideoSuccess,
    setCurrentMeetingId,
    resetQuestions,
    setCommonErrors,
    uploadMeetingAudioSuccess,
    setSpeechTokenResponse,
    setCurrentProgramDetails,
    resetCurrentProgramDetails,
    hasCodingError,
    addInterviewEventSuccess,
    getQuestionVoiceSuccess,
} = interviews.actions;

export default interviews.reducer;

export function getInterviewDetails(ids) {
    return async function getAllinterviewsListThunk(dispatch, getState) {
        dispatch(setLoading());
        try {
            const response = await axios.get(`/candidate/v1/interviews_ext/${ids}`);
            if (response.status === 200) {
                dispatch(setInterviewResponse(response.data.data));
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

export function getInterviewTokens(ids) {
    return async function getInterviewTokensThunk(dispatch, getState) {
        dispatch(setLoading());
        try {
            const response = await axios.get(`/candidate/v1/auth/int_auth/verify/${ids}`);
            if (response.status === 200) {
                const serviceToken = response.data.data.user.token;
                setToken(serviceToken);
                setAxiosToken(serviceToken);
                // console.log("getInterviewTokens", response.data.data);
                dispatch(setInterviewTokenResponse(response.data.data));
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

export function postInterviewDetails(payload, ids) {
    return async function postinterviewsListThunk(dispatch, getState) {
        dispatch(setLoading());
        try {
            const response = await axios.put(`/candidate/v1/interviews/updateclient/${ids}`, payload);
            if (response.status === 200) {
                dispatch(setPostInterviewSuccess(response.data.data));
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

export function uploadMeetingVideo(blob, ids, types) {
    const formData = new FormData();
    formData.append('file', blob);
    formData.append('type', types);
    ids = ids + "_" + types;
    return async function uploadMeetingThunk(dispatch, getState) {
        try {
            const response = await axios.post(`/candidate/v1/interviews/uploadIntVideo/${ids}`, formData);
            if (response.status === 200) {
                if (types === 'a') {
                    dispatch(uploadMeetingAudioSuccess(response.data.data));
                }
                else if (types === 'v') {
                    dispatch(uploadMeetingVideoSuccess(response.data.data));
                }
            }
            // console.log("response", response);
        } catch (error) {
            console.error("response", error);
            dispatch(hasError(error));
        }
    };
}

export function getIntSpeechToken() {
    return async function getAllinterviewsListThunk(dispatch, getState) {
        dispatch(setLoading());
        try {
            const response = await axios.get(`/candidate/v1/interviews/gettoken`);
            if (response.status === 200) {
                dispatch(setSpeechTokenResponse(response.data.data));
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// export function uploadFileToServer(blob, ids, types) {
//     const formData = new FormData();
//     formData.append('file', blob);
//     formData.append('type', types);
//     ids = ids + "_" + types;
//     let filename = `Video-${Date.now().toString()}-${ids}.webm`
//     return async function getAllinterviewsListThunk(dispatch, getState) {
//         dispatch(setLoading());
//         try {
//             const presignedUrl = await requestPresignedUrl(filename);
//             if (presignedUrl.status === 'SUCCESS') {
//                 const response = await s3Axios.put(presignedUrl.data, blob, {
//                     headers: {
//                         'Content-Type': blob.type
//                     }
//                 });
//                 if (response.status === 200) {
//                     console.log("aaa", response);
//                     if (types === 'a') {
//                         dispatch(uploadMeetingAudioSuccess(response.data));
//                     }
//                     else if (types === 'v') {
//                         dispatch(uploadMeetingVideoSuccess(response.data));
//                     }
//                     const uploadobj_response = await addFileUploads(filename, ids);
//                 }
//             }
//         } catch (error) {
//             dispatch(hasError(error));
//         }
//     };
// }

const retryUploadPart = async (url, chunk, maxRetries, attempt = 1) => {
    try {
        const response = await s3Axios.put(url, chunk, {
            headers: { 'Content-Type': 'video/webm' },
            onUploadProgress: (progressEvent) => {
                const progress = (progressEvent.loaded / progressEvent.total * 100).toFixed(2);
                // console.log(`Chunk retry attempt ${attempt}: ${progress}%`);
            }
        });
        return response; // Return the successful response
    } catch (error) {
        if (attempt < maxRetries) {
            console.log(`Retry attempt ${attempt} for chunk failed, retrying...`);
            return retryUploadPart(url, chunk, maxRetries, attempt + 1);
        } else {
            throw new Error(`Failed after ${maxRetries} retries`);
        }
    }
};

export function uploadFileToServer(blob, ids, types) {
    const formData = new FormData();
    formData.append('file', blob);
    formData.append('type', types);
    ids = ids + "_" + types;

    let filename = `Video-${Date.now().toString()}-${ids}.webm`
    const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB
    let currentPosition = 0;
    const fileChunks = [];

    while (currentPosition < blob.size) {
        const chunk = blob.slice(currentPosition, currentPosition + CHUNK_SIZE);
        fileChunks.push(chunk);
        currentPosition += CHUNK_SIZE;
    }

    return async function getAllinterviewsListThunk(dispatch, getState) {
        dispatch(setLoading());
        try {
            const presignedUrlsResponse = await requestPresignedUrl(filename, fileChunks.length);
            if (presignedUrlsResponse.status === 'SUCCESS') {
                const urls = presignedUrlsResponse.data.presignedUrls;
                const uploadid = presignedUrlsResponse.data.uploadId;
                const MAX_RETRIES = 3;

                const uploadPromises = fileChunks.map((chunk, index) =>
                    retryUploadPart(urls[index], chunk, MAX_RETRIES)
                );

                try {
                    const responses = await Promise.all(uploadPromises);
                    // console.log('All parts uploaded successfully.');
                    const uploadobj_response = dispatch(addFileUploads(filename, ids, uploadid, responses.map((response, index) => ({
                        ETag: response.headers.etag,
                        PartNumber: index + 1
                    })), types));

                } catch (error) {
                    console.error('Error uploading chunks:', error);
                }
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

export async function requestPresignedUrl(filename, noofparts) {
    const params = new URLSearchParams();
    params.append('newfilename', filename);
    params.append('parts', noofparts);
    try {
        const response = await axios.post('/candidate/v1/interviews/getUploadUrl', params);
        return response.data;
    } catch (error) {
        console.error('Error requesting pre-signed URL:', error);
        throw error;
    }
};

export function addFileUploads(filenames, fnames, uploadid, parts, types) {
    let fileuploadObj = {
        filename: filenames,
        fname: fnames,
        parts: parts,
        uploadid: uploadid
    };
    return async function addFileUploadsThunk(dispatch, getState) {
        try {
            const response = await axios.post('/candidate/v1/interviews/addfileupload', fileuploadObj);
            if (response.status === 200) {
                if (types === 'a') {
                    dispatch(uploadMeetingAudioSuccess(response.data));
                }
                else if (types === 'v') {
                    dispatch(uploadMeetingVideoSuccess(response.data));
                }
            }
            return response.data.data;
        } catch (error) {
            console.error('Error requesting pre-signed URL:', error);
            throw error;
        }
    }
}

export function updateCodingPrograms(ids, payload) {
    if (!ids) {
        ids = 1;
    }
    return async function getAllinterviewsListThunk(dispatch, getState) {
        dispatch(setLoading());
        try {
            const response = await axios.post(`/candidate/v1/interviews/updateprograms/${ids}`, payload);
            if (response.status === 200) {
                dispatch(setCurrentProgramDetails(response.data.data));
            }
        } catch (error) {
            dispatch(hasCodingError(error));
        }
    };
}

//types: 1:meeting_Start, 2: meeting_end, 3:ping,4:new_question,5:FULL_SCreen, 6:VIDEO_OBSCTRUCTION,7:metadata_successful, 8: tab_changes
export function addInterviewEvents(ivid, type, details) {
    const payload = {
        ivid: ivid,
        type: type,
        details: details
    }
    return async function uploadMeetingThunk(dispatch, getState) {
        try {
            const response = await axios.post(`/candidate/v1/interviews/events/create`, payload);
            if (response.status === 200) {
                dispatch(addInterviewEventSuccess(response.data.data));
            }
        } catch (error) {
            console.error("response", error);
            dispatch(hasError(error));
        }
    };
}

export function getQuestionVoice(ques_Text) {
    const payloads = {
        ques_text: ques_Text
    }
    return async function uploadMeetingThunk(dispatch, getState) {
        try {
            const response = await axios.post(`/candidate/v1/interviews/getQuestion`, payloads, {
                responseType: 'blob'
            });
            if (response.status === 200) {
                const blob = response.data;
                const url = URL.createObjectURL(blob);
                dispatch(getQuestionVoiceSuccess(url));
                return response.data;
            }
        } catch (error) {
            console.log("response", error);
            dispatch(hasError(error));
        }
    };
}

export async function requestScreenShotUrl(ivid, pagename) {
    const payloads = {
        fileName: ivid,
        pname: pagename
    }
    try {
        const response = await axios.post('/candidate/v1/interviews/getUploadUrlForSS', payloads);
        return response.data;
    } catch (error) {
        console.error('Error requesting pre-signed URL:', error);
        throw error;
    }
};

export function uploadScreenshotToServer(ivid, ssfile, pagename) {
    return async function uploadScreenshotToServerThunk(dispatch, getState) {
        try {
            const presignedUrlsResponse = await requestScreenShotUrl(ivid, pagename);
            if (presignedUrlsResponse.status === 'SUCCESS') {
                const uploadUrl = presignedUrlsResponse.data;
                try {
                    const response = await s3Axios.put(uploadUrl, ssfile, {
                        headers: {
                            "Content-Type": ssfile.type,
                        },
                    });
                    if (response.status === 200) {
                        // console.log("Screenshot successfully uploaded");
                    }

                } catch (error) {
                    console.error('Error uploading Screenshot:', error);
                }
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}