import React from 'react';
import { Dialog, DialogContent, DialogActions, Typography } from '@mui/material';
import LoadingButton from "../LoadingButton";

export default function MeetingDialog({
    handleSubmit,
    handleCancel,
    isMeetingModalOpen,
    dialogTitle,
    primaryMessage,
    confirmationQuestion,
    cancelButtonText = "Cancel",
    submitButtonText,
    showPrimaryMessage = true,
    showConfirmationQuestion = true,
    showCancelButton = true,
    showSubmitButton = true,
}) {
    return (
        <>
            <Dialog
                open={isMeetingModalOpen}
                onClose={() => { }} // Empty function to ensure modal can't be closed by default actions
                PaperProps={{
                    style: {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        color: 'white',
                        borderRadius: '15px',
                        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.4)',
                    }
                }}
            >
                <DialogContent>
                    <Typography variant="h5" gutterBottom>
                        {dialogTitle}
                    </Typography>
                    {showPrimaryMessage && (
                        <Typography variant="body1" gutterBottom>
                            {primaryMessage}
                        </Typography>
                    )}
                    {showConfirmationQuestion && (
                        <Typography variant="body2">
                            {confirmationQuestion}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    {showCancelButton && (
                        <LoadingButton
                            variant="outlined"
                            color="primary"
                            loading={false}
                            onClick={handleCancel}>
                            {cancelButtonText}
                        </LoadingButton>
                    )}
                    {showSubmitButton && (
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            loading={false}
                            onClick={handleSubmit}>
                            {submitButtonText}
                        </LoadingButton>
                    )}
                </DialogActions>
            </Dialog>
        </>
    )
}
