import { Button, CircularProgress, Box } from '@mui/material';

function LoadingButton({ isDisabled, isLoading, children, ...props }) {
    return (
        <Button {...props} disabled={isDisabled}>
            {isLoading ? (
                <Box display="flex" alignItems="center" justifyContent="center" borderRadius="50%" border="1px solid white" width={24} height={24}>
                    <CircularProgress size={20} color="secondary" />
                </Box>
            ) : (
                children
            )}
        </Button>
    );
}

export default LoadingButton;
